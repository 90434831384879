import { FC } from 'react';
import BarChart from './bar-chart/BarChart';
import LineChart from './line-chart/LineChart';
import TwitterMentions from './twitter-mentions/TwitterMentions';

export interface DetailComponent extends FC {
  fieldInfo: any;
  fields: any[];
  viewInterval: string;
}

const DETAILS: any = {
  'bar-chart': BarChart,
  'line-chart': LineChart,
  'twitter-mentions': TwitterMentions,
};

export const getDetailComponent = (type: string): DetailComponent => {
  return DETAILS[type] || null;
};
