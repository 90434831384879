import React, { FC } from 'react';
import classNames from 'classnames';
import { ResponsiveLine } from 'nivo';
import { format, abbreviate } from '../../../../../lib/utils';
import { formatFields } from './utils';
import './LineChart.scss';

interface Props {
  fieldInfo: any;
  fields: any[];
  viewInterval: string;
}

const LineChart: FC<Props> = ({ fieldInfo, fields, viewInterval }) => {
  if (!fieldInfo.fieldId) return null;

  const formattedViewItems = formatFields({
    fields,
    viewInterval,
  });
  const data = {
    id: fieldInfo.name,
    data: formattedViewItems,
  };

  const formatTooltip = (value: any) => {
    let newValue = value;

    if (
      fieldInfo.type === 'number' ||
      fieldInfo.type === 'percentage' ||
      fieldInfo.type === 'currency'
    ) {
      newValue = format(newValue, {
        ...fieldInfo,
        currency: 'usd',
        decimalPlaces: fieldInfo.type === 'percentage' ? 2 : 0,
      });
    }

    return newValue;
  };

  const formatAxisLeft = (value: any) => {
    let newValue = value;

    if (
      fieldInfo.type === 'number' ||
      fieldInfo.type === 'percentage' ||
      fieldInfo.type === 'currency'
    ) {
      newValue = abbreviate(newValue, fieldInfo);
    }

    return newValue;
  };

  // const tooltip = (value: any) => {
  //   return <div>{value.point.data.yFormatted}</div>;
  // };

  const classes = classNames('chart', {
    'reduce-labels': formattedViewItems.length > 10,
  });

  return (
    <div className="line-chart">
      <div className={classes}>
        <ResponsiveLine
          data={[data]}
          margin={{
            top: 10,
            bottom: 50,
            left: 60,
            right: 40,
          }}
          curve="monotoneX"
          colors={['#2165f5']}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          enableGridX={false}
          enableGridY={true}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltipFormat={formatTooltip}
          axisLeft={{ format: formatAxisLeft }}
          useMesh
        />
      </div>
    </div>
  );
};

export default LineChart;
