import _ from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const delay = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time || 0);
  });
};

export const sequence = async ({
  method,
  list,
  showLogs = false,
  nextDelay = 0,
  data,
}) => {
  let currentIndex = 0;

  const next = async () => {
    try {
      await method(list[currentIndex], data, {
        index: currentIndex,
        total: list.length,
      });

      if (showLogs) {
        console.log(
          'completed - sequence',
          currentIndex + 1,
          'of',
          list.length,
        );
      }
    } catch (error) {
      if (showLogs) {
        console.log('error - sequence', currentIndex + 1, 'of', list.length);
      }
    }

    currentIndex++;
    if (currentIndex + 1 <= list.length) {
      await delay(nextDelay);
      await next();
    }
  };

  if (list.length) {
    await next();
  }
};

export const numberWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return number;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isJSON = (item) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

export const stringHasSpaces = (string) => {
  if (string && typeof string === 'string') {
    return string.indexOf(' ') === -1 ? false : true;
  }

  return false;
};

export const getQuery = () => {
  const query = {};
  const [, queryString] = window.location.href.split('?');

  if (queryString) {
    const pairs = queryString.split('&');

    pairs.forEach((pair) => {
      let [key, value] = pair.split('=');

      query[key] = value;

      return pair;
    });
  }

  return query;
};

export const getDomainWithProtocol = (domain) => {
  return `https://${domain}`;
};

export const getVersionFromImage = (image) => {
  if (image && image.includes(':')) {
    return image.split(':')[1];
  }
};

export const format = (value, settings = {}) => {
  const config = {
    decimalPlaces: settings.decimalPlaces || 0,
  };

  BigNumber.config({
    FORMAT: {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: ' ',
      fractionGroupSize: 0,
    },
  });

  let number = new BigNumber(value);
  let newValue = value;

  newValue = number.toFormat(config.decimalPlaces);

  if (settings.type === 'percentage' || settings.valueType === 'percentage') {
    newValue = `${newValue}%`;
  }

  if (settings.type === 'currency' || settings.valueType === 'currency') {
    newValue = `${getCurrencySymbol(settings.currency)}${newValue}`;
  }

  return newValue;
};

export const abbreviate = (value, settings = {}) => {
  let newValue = value;

  if (value >= 1000) {
    let suffixes = ['', 'k', 'm', 'b', 't'];
    let suffixNum = Math.floor(('' + value).length / 3);
    let shortValue = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      );
      let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }

    newValue = shortValue + suffixes[suffixNum];
  }

  if (settings.type === 'percentage') {
    newValue = `${newValue}%`;
  }

  return newValue;
};

export const randomNumber = ({ min, max }) => {
  return Math.floor(Math.random() * max) + min;
};

const CURRENCY_SYMBOLS = {
  usd: '$',
  gbp: '£',
};

export const getCurrencySymbol = (currency) => {
  return CURRENCY_SYMBOLS[currency] || '$';
};

const cards = [
  {
    id: 'amex',
    name: 'American Express',
  },
  {
    id: 'diners',
    name: 'Diners Club',
  },
  {
    id: 'discover',
    name: 'Discover',
  },
  {
    id: 'jcb',
    name: 'JCB',
  },
  {
    id: 'mastercard',
    name: 'MasterCard',
  },
  {
    id: 'visa',
    name: 'Visa',
  },
];

export const getCardBrandByName = (name) => {
  return _.find(cards, { name }) || { id: 'unknown', name };
};

export const handleEnterKey = (callback) => (event) => {
  if (event.key === 'Enter') {
    callback();
  }
};
