import React, { FC } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import classNames from 'classnames';
import SuccessCheckMark from './success-check-mark/SuccessCheckMark';
import './Result.scss';

interface Props {
  title?: string;
  actions?: any;
  content?: any;
  type?: 'success' | 'warning' | undefined;
}

const TYPES: any = {
  success: SuccessCheckMark,
  warning: ErrorOutline,
};

const Result: FC<Props> = ({ title, actions, content, type }) => {
  const classes = classNames('result');
  const IconType = type ? TYPES[type] : null;

  return (
    <div className={classes}>
      <div className="result-inner">
        <Card>
          <CardContent className="content">
            {type && (
              <div className="icon">
                <IconType />
              </div>
            )}
            <Typography variant="h5">{title}</Typography>

            {content}

            {actions && <div className="actions">{actions}</div>}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Result;
