import { createMuiTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2165f5',
    },
    secondary: {
      main: '#838896',
    },
    success: {
      main: '#0ccb89',
    },
    error: {
      main: '#d7153f',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    action: {
      disabledBackground: 'rgba(33, 101, 245, 0.5)',
      disabled: '#fff',
    },
    divider: '#f0f0f0',
  },
  typography: {
    fontFamily: 'Muli',
  },
  shape: {
    borderRadius: 8,
  },
  shadows: Array(25).fill('0px 2px 15px rgba(0, 0, 0, 0.05)') as Shadows,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});
