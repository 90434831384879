import React, { FC } from 'react';
import { BasicPageLayout } from '../../page-layout';
import { getViewInfo } from '../../../../lib/views/utils';
import ViewGrid from '../../view-grid/ViewGrid';
import { PageDetails } from '../../../../common/types';
// import ViewsNav from './views-nav/ViewsNav';
import './ViewPage.scss';

interface Props {
  currentPage: PageDetails;
  match: any;
  history: any;
}

export const Page: FC<Props> = ({ currentPage, match, history }) => {
  const {
    params: { viewId },
  } = match;
  const viewInfo: any = getViewInfo(viewId);
  const pageTitle = viewInfo.name;
  const goBack = () => {
    history.push('/');
  };

  return (
    <BasicPageLayout
      currentPage={currentPage}
      title={pageTitle}
      onBack={goBack}
    >
      <ViewGrid viewId={viewId} viewInfo={viewInfo} />

      {/* <ViewsNav viewInfo={viewInfo} /> */}
    </BasicPageLayout>
  );
};

export default Page;
