import React, { FC } from 'react';
import './Logo.scss';

const Logo: FC = () => {
  return (
    <div className="logo">
      <div className="logo-inner">
        <img src="/assets/icon.svg" alt="Unubo" />
      </div>
    </div>
  );
};

export default Logo;
