import React, { FC } from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BasicPageLayout } from '../../page-layout';
import { VIEWS } from '../../../../lib/views/utils';
import { PageDetails } from '../../../../common/types';
import './ViewsPage.scss';

interface Props {
  currentPage: PageDetails;
}

const Page: FC<Props> = ({ currentPage }) => {
  return (
    <BasicPageLayout currentPage={currentPage} centerTitle>
      <Grid className="views" container spacing={3}>
        {VIEWS.map((view) => {
          return (
            <Grid key={view.id} item xs={12} sm={3}>
              <Link to={`/${view.id}`}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <Card className="view">
                    <CardContent>
                      <Typography>{view.name}</Typography>

                      <div className="icons">
                        {view.services.map((serviceId: string) => (
                          <div key={serviceId} className="icon">
                            <img
                              src={`/assets/services/${serviceId}/icon.svg`}
                              alt={serviceId}
                            />
                          </div>
                        ))}
                      </div>
                    </CardContent>
                  </Card>
                </motion.div>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </BasicPageLayout>
  );
};

export default Page;
