import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './lib/history';
import * as ModeAdminContainers from './containers/mode-admin';
import './styles/global.scss';

function App() {
  return (
    <Router history={history}>
      <Switch>
        {/* views */}
        <Route
          path="/"
          render={(props) => (
            <ModeAdminContainers.PageContainer pageId="views" {...props} />
          )}
          exact
        />
        <Route
          path="/:viewId"
          render={(props) => (
            <ModeAdminContainers.PageContainer pageId="view" {...props} />
          )}
          exact
        />

        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}

export default App;
