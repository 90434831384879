import _ from 'lodash';
import pages from './pages';

export const DEFAULT_PAGE_PATH = '/views';

export const getPage = (pageId) => {
  return _.find(pages, { pageId });
};

export { pages };
