import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { motion } from 'framer-motion';
import './BasicPageLayout.scss';
import { PageDetails } from '../../../../common/types';

interface Props {
  currentPage: PageDetails;
  title?: string;
  onBack?: () => void;
  hideHeader?: boolean;
  centerTitle?: boolean;
  titleInHeader?: boolean;
  fullWidth?: boolean;
}

const BasicPageLayout: FC<Props> = ({
  currentPage,
  title,
  onBack,
  hideHeader,
  centerTitle,
  titleInHeader,
  fullWidth,
  children,
}) => {
  const pageClasses = classNames('page', `page-${currentPage.pageId}`);
  const pageLayoutClasses = classNames('page-layout', 'page-layout-basic', {
    'center-title': centerTitle,
    'title-in-header': titleInHeader,
    'full-width': fullWidth,
  });

  useEffect(() => {
    document.title = `${title || currentPage.title} - Unubo Demo`;
  }, [title, currentPage]);

  return (
    <>
      <div className={pageClasses}>
        <div className={pageLayoutClasses}>
          <div className="page-inner">
            {!hideHeader && (
              <div className="page-header">
                <div className="page-title-outer">
                  {onBack && (
                    <motion.div
                      className="back"
                      onClick={onBack}
                      whileHover={{ x: -5 }}
                    >
                      <KeyboardBackspaceIcon />
                    </motion.div>
                  )}

                  <Typography
                    className="page-title"
                    variant="h5"
                    component="h1"
                  >
                    {title || currentPage.title}
                  </Typography>
                </div>
              </div>
            )}

            <div className="page-content">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicPageLayout;
