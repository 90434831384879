import React, { FC } from 'react';
import Logo from './logo/Logo';
import config from '../../../lib/config';
import './Header.scss';

const Header: FC = () => {
  return (
    <div className="header">
      <a href={config.WEBSITE_URL}>
        <Logo />
      </a>

      <div className="demo-tag">Demo</div>
    </div>
  );
};

export default Header;
