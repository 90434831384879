import { FC } from 'react';
import Basic from './basic/Basic';
import List from './list/List';
import Countries from './countries/Countries';

interface Props {
  value: any;
  type: string;
}

const VALUE_TYPES: any = {
  number: Basic,
  percentage: Basic,
  currency: Basic,
  list: List,
  countries: Countries,
  'twitter-mentions': List,
};

export const getValueComponent = (type: string): FC<Props> => {
  return VALUE_TYPES[type] || null;
};
