import dayjs from 'dayjs';

export const formatFieldsDay = ({ fields }: { fields: any[] }) => {
  const formattedFields = fields.map((field) => {
    const date = dayjs(field.second).startOf('hour');
    const key = date.format('H:mm');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ key, value, type: field.type });

    return formattedValue;
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

export const formatFieldsWeek = ({ fields }: { fields: any[] }) => {
  const formattedFields = fields.map((field) => {
    const date = dayjs(field.second).startOf('day');
    const key = date.format('D MMM');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ key, value, type: field.type });

    return formattedValue;
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

export const formatFieldsMonth = ({ fields }: { fields: any[] }) => {
  const formattedFields = fields.map((field) => {
    const date = dayjs(field.second).startOf('day');
    const key = date.format('D MMM');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ key, value, type: field.type });

    return formattedValue;
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

export const formatFieldsYear = ({ fields }: { fields: any[] }) => {
  const formattedFields = fields.map((field) => {
    const date = dayjs(field.second).startOf('day');
    const key = date.format('MMM YYYY');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ key, value, type: field.type });

    return formattedValue;
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

const formatFieldValue = ({
  key,
  value,
  type,
}: {
  key: string;
  value: any;
  type: string;
}) => {
  const formattedValue: any = { key };

  value.forEach((item: any) => {
    formattedValue[item.label] = item.value;
  });

  return formattedValue;

  // switch (true) {
  //   case Array.isArray(value):
  //     value = value.length;
  //     break;
  //   default:
  //     break;
  // }

  // return value;
};

const VIEW_ITEMS_METHODS: any = {
  day: formatFieldsDay,
  week: formatFieldsWeek,
  month: formatFieldsMonth,
  year: formatFieldsYear,
};

export const formatFields = ({
  fields,
  viewInterval,
}: {
  fields: any[];
  viewInterval: string;
}) => {
  return VIEW_ITEMS_METHODS[viewInterval]({ fields });
};

export const getKeys = (fields: any[]): string[] => {
  const uniqueKeys = new Set();

  fields.forEach((field: any) => {
    field.data.value.forEach((item: any) => {
      uniqueKeys.add(item.label);
    });
  });

  const keys = Array.from(uniqueKeys) as string[];

  return keys;
};
