import React, { FC } from 'react';
import { getPage } from '../../../lib/pages';
import pages from '../../../components/mode-admin/pages';
import Header from '../../../components/mode-admin/header/Header';
import Banner from '../../../components/mode-admin/banner/Banner';

interface Props {
  pageId: string;
}

const Container: FC<Props> = (props: any) => {
  const Page = pages[props.pageId];
  const currentPage = getPage(props.pageId);

  return (
    <>
      <Header />
      <Page {...props} currentPage={currentPage} />
      <Banner />
    </>
  );
};

export default Container;
