import React, { FC } from 'react';
import { Value } from '../../../ui';

interface Props {
  value: any;
  type: string;
}

const List: FC<Props> = ({ value, type }) => {
  return <Value value={value.length} />;
  // if (!value.length) return <Value value={0} />;

  // return (
  //   <div className="list">
  //     {value.map((item: any, index: number) => {
  //       return (
  //         <div key={index} className="list-item">
  //           {item.label}
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
};

export default List;
