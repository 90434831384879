import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { Typography, CardContent } from '@material-ui/core';
import { formatValue } from '../../../lib/views/utils';
import { getValueComponent } from './value';
import './ViewCard.scss';

interface Props {
  fields: any;
}

const ViewCard: FC<Props> = ({ fields }) => {
  if (!fields) return null;

  const mainField = fields[0];
  const mainFieldData = mainField.data || {};
  const mainFormattedValue = formatValue({
    value: mainFieldData.value,
    type: mainField.type,
    data: { currency: mainFieldData.currency },
  });
  const ValueComponent = getValueComponent(mainField.type);

  return (
    <Card className="view-card">
      <CardContent>
        <Typography className="view-name">{mainField.name}</Typography>

        {ValueComponent && (
          <ValueComponent value={mainFormattedValue} type={mainField.type} />
        )}
      </CardContent>
    </Card>
  );
};

export default ViewCard;
