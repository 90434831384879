import React from 'react';
import { Button } from '@material-ui/core';
import config from '../../../lib/config';
import './Banner.scss';

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-inner">
        <h2 className="banner-message">
          See all metrics from your{' '}
          <span className="no-wrap">favourite tools in one place.</span>
        </h2>

        <a className="signup" href={`${config.WEB_APP_URL}/signup`}>
          <Button
            className="signup-btn"
            variant="contained"
            color="primary"
            size="large"
          >
            Sign Up
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Banner;
