import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import { motion } from 'framer-motion';
import './PageLoading.scss';

interface Props {
  delay?: number;
}

const PageLoading: FC<Props> = ({ delay }) => {
  return (
    <motion.div
      className="ui-page-loading"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: delay || 1 }}
    >
      <CircularProgress />
    </motion.div>
  );
};

export default PageLoading;
