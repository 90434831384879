import React, { FC, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import {
  Typography,
  CardContent,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  VIEW_INTERVALS,
  ViewCardDetail,
  getViewCardDetails,
} from '../../../lib/views/utils';
import { getDetailComponent, DetailComponent } from './details';
import './ViewCardDetails.scss';

interface Props {
  viewFields: any[];
  fieldInfo: any;
  viewInterval: any;
  setViewInterval: (intervalId: string) => void;
  hideField: () => void;
}

const ViewCardDetails: FC<Props> = ({
  viewFields,
  fieldInfo,
  viewInterval,
  setViewInterval,
  hideField,
}) => {
  if (!fieldInfo) return null;
  const fields = viewFields[fieldInfo.fieldId] || [];
  const details = getViewCardDetails(fieldInfo);
  const detailComponents: DetailComponent[] = [];

  details.forEach((detail: ViewCardDetail) => {
    const Component = getDetailComponent(detail.type);

    if (Component) {
      detailComponents.push(Component);
    }
  });

  return (
    <div className="view-card-details">
      <div className="background" onClick={hideField} />

      <Card className="view-card-details-card">
        <CardContent>
          {!fieldInfo.hideHeader && (
            <>
              <div className="view-card-details-heading">
                <Typography variant="h5">{fieldInfo.name}</Typography>

                <Select
                  className="interval-select"
                  disableUnderline
                  value={viewInterval}
                  onChange={(event) =>
                    setViewInterval(event.target.value as string)
                  }
                >
                  {VIEW_INTERVALS.map((interval) => (
                    <MenuItem key={interval.id} value={interval.id}>
                      {interval.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <Divider style={{ marginBottom: 20 }} />
            </>
          )}

          {detailComponents.map((Component: any, index: number) => {
            return (
              <Fragment key={index}>
                {index !== 0 && <Divider />}

                <Component
                  fieldInfo={fieldInfo}
                  fields={fields}
                  viewInterval={viewInterval}
                />
              </Fragment>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewCardDetails;
