import React, { FC } from 'react';
import classNames from 'classnames';
import { ResponsiveBar } from '@nivo/bar';
import { format } from '../../../../../lib/utils';
import { formatFields, getKeys } from './utils';
import './BarChart.scss';

interface Props {
  fieldInfo: any;
  fields: any[];
  viewInterval: string;
}

const BarChart: FC<Props> = ({ fieldInfo, fields, viewInterval }) => {
  if (!fieldInfo.fieldId) return null;
  const formattedFields = formatFields({
    fields,
    viewInterval,
  });
  const keys = getKeys(fields);
  // console.log(formattedFields);
  // const data = {
  //   id: fieldInfo.name,
  //   data: formattedViewFields,
  // };

  const formatTooltip = (value: any) => {
    let newValue = value;

    if (
      fieldInfo.type === 'number' ||
      fieldInfo.type === 'percentage' ||
      fieldInfo.type === 'currency'
    ) {
      newValue = format(newValue, {
        ...fieldInfo,
        currency: 'usd',
        decimalPlaces: fieldInfo.type === 'percentage' ? 2 : 0,
      });
    }

    return newValue;
  };

  // const formatAxisLeft = (value: any) => {
  //   let newValue = value;

  //   if (
  //     fieldInfo.type === 'number' ||
  //     fieldInfo.type === 'percentage' ||
  //     fieldInfo.type === 'currency' ||
  //     fieldInfo.valueType === 'number' ||
  //     fieldInfo.valueType === 'percentage' ||
  //     fieldInfo.valueType === 'currency'
  //   ) {
  //     newValue = abbreviate(newValue, fieldInfo);
  //   }

  //   return newValue;
  // };

  const labelFormat = (value: any) => {
    let newValue = value;

    if (
      fieldInfo.type === 'number' ||
      fieldInfo.type === 'percentage' ||
      fieldInfo.type === 'currency' ||
      fieldInfo.valueType === 'number' ||
      fieldInfo.valueType === 'percentage' ||
      fieldInfo.valueType === 'currency'
    ) {
      newValue = format(newValue, {
        ...fieldInfo,
        currency: 'usd',
        decimalPlaces:
          fieldInfo.type === 'percentage' ||
          fieldInfo.valueType === 'percentage'
            ? 2
            : 0,
      });
    }

    return newValue;
  };

  const classes = classNames('chart', {
    'reduce-labels': formattedFields.length > 10,
  });

  return (
    <div className="bar-chart">
      <div className={classes}>
        <ResponsiveBar
          data={formattedFields}
          keys={keys}
          indexBy="key"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltipFormat={formatTooltip}
          labelFormat={labelFormat}
        />
      </div>
    </div>
  );
};

export default BarChart;
