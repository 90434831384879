import React, { FC } from 'react';
import { Value } from '../../../ui';

interface Props {
  value: any;
  type: string;
}

const Basic: FC<Props> = ({ value, type }) => {
  return <Value value={value} type={type} />;
};

export default Basic;
