declare global {
  interface Window {
    config: any;
  }
}
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const config = {
  ENVIRONMENT,
  NODE_ENV: process.env.NODE_ENV,
  VERSION: '0.0.1-alpha',
  WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
  IS_LOCAL: ENVIRONMENT === 'local',
  IS_STAGING: ENVIRONMENT === 'staging',
  IS_PRODUCTION: ENVIRONMENT === 'production',
};

if (!config.IS_PRODUCTION) {
  window.config = config;
}

export default config;
