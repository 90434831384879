import React, { FC } from 'react';
import classNames from 'classnames';
import './Value.scss';

interface Props {
  value: string | number;
  type?: string;
  className?: string;
}

const Value: FC<Props> = ({ value, type = 'number', className }) => {
  const classes = classNames('ui-value', `ui-${type || 'text'}`, className);

  return <div className={classes}>{value}</div>;
};

export default Value;
