import dayjs from 'dayjs';
import _ from 'lodash';

export const formatFieldsDay = ({ fields }: { fields: any[] }) => {
  const uniqueFields = _.uniqBy(fields, 'uniqueHour');
  const formattedFields = uniqueFields.map((field) => {
    const date = dayjs(field.second).startOf('hour');
    const label = date.format('H:mm');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ value, type: field.type });

    return {
      x: label,
      y: formattedValue,
    };
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

export const formatFieldsWeek = ({ fields }: { fields: any[] }) => {
  const uniqueFields = _.uniqBy(fields, 'uniqueDay');
  const formattedFields = uniqueFields.map((field) => {
    const date = dayjs(field.second).startOf('day');
    const label = date.format('D MMM');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ value, type: field.type });

    return {
      x: label,
      y: formattedValue,
    };
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

export const formatFieldsMonth = ({ fields }: { fields: any[] }) => {
  const uniqueFields = _.uniqBy(fields, 'uniqueDay');
  const formattedFields = uniqueFields.map((field) => {
    const date = dayjs(field.second).startOf('day');
    const label = date.format('D MMM');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ value, type: field.type });

    return {
      x: label,
      y: formattedValue,
    };
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

export const formatFieldsYear = ({ fields }: { fields: any[] }) => {
  const uniqueFields = _.uniqBy(fields, 'uniqueMonth');
  const formattedFields = uniqueFields.map((field) => {
    const date = dayjs(field.second).startOf('month');
    const label = date.format('MMM YYYY');
    const { value } = field.data;
    const formattedValue = formatFieldValue({ value, type: field.type });

    return {
      x: label,
      y: formattedValue,
    };
  });
  const reversedFormattedFields = formattedFields.slice().reverse();

  return reversedFormattedFields;
};

const formatFieldValue = ({ value, type }: { value: any; type: string }) => {
  switch (true) {
    case Array.isArray(value):
      value = value.length;
      break;
    default:
      break;
  }

  return value;
};

const VIEW_ITEMS_METHODS: any = {
  day: formatFieldsDay,
  week: formatFieldsWeek,
  month: formatFieldsMonth,
  year: formatFieldsYear,
};

export const formatFields = ({
  fields,
  viewInterval,
}: {
  fields: any[];
  viewInterval: string;
}) => {
  return VIEW_ITEMS_METHODS[viewInterval]({ fields });
};
