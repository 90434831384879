import React, { FC, Fragment } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@material-ui/core';
import './TwitterMentions.scss';

interface Props {
  fieldInfo: any;
  fields: any[];
  viewInterval: string;
}

const TwitterMentions: FC<Props> = ({ fieldInfo, fields, viewInterval }) => {
  const mentions = _.get(fields, '[0].data.value', []);

  return (
    <List className="twitter-mentions">
      {mentions.map((mention: any, index: number) => {
        const url = `https://twitter.com/${mention.user.screen_name}/status/${mention.id_str}`;

        return (
          <Fragment key={mention.id}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <ListItem className="twitter-mention">
                <ListItemAvatar>
                  <Avatar src={mention.user.profile_image_url_https} />
                </ListItemAvatar>

                <ListItemText
                  primary={`${mention.user.name} (${dayjs(
                    mention.created_at,
                  ).fromNow()})`}
                  secondary={mention.text}
                />
              </ListItem>
            </a>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Fragment>
        );
      })}
    </List>
  );
};

export default TwitterMentions;
