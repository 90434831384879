import _ from 'lodash';
import { numberWithCommas, getCurrencySymbol } from '../utils';
import growth from './demo/growth.json';
import newsletter from './demo/newsletter.json';
import social from './demo/social.json';
import traffic from './demo/traffic.json';

export type ViewCardDetail = {
  type: string;
};

export const VIEWS = [
  {
    id: 'growth',
    name: 'Growth',
    services: ['stripe'],
    description: "Track how you're growing.",
    fields: [
      { fieldId: 'mrr', name: 'MRR', type: 'currency' },
      { fieldId: 'arr', name: 'ARR', type: 'currency' },
      { fieldId: 'retention', name: 'Customer retention', type: 'percentage' },
      { fieldId: 'people', name: 'People', type: 'number' },
      { fieldId: 'customers', name: 'Customers', type: 'number' },
      { fieldId: 'churn', name: 'Customer churn', type: 'percentage' },
    ],
  },
  {
    id: 'newsletter',
    name: 'Newsletter',
    services: ['mailchimp'],
    description: 'Track how your newsletter is performing.',
    fields: [
      { fieldId: 'subscribers', name: 'Subscribers', type: 'number' },
      { fieldId: 'open-rate', name: 'Open rate', type: 'percentage' },
      { fieldId: 'click-rate', name: 'Click rate', type: 'percentage' },
    ],
  },
  {
    id: 'social',
    name: 'Social',
    services: ['twitter'],
    description: "See if you've been mentioned online.",
    fields: [
      { fieldId: 'followers', name: 'Followers', type: 'number' },
      {
        fieldId: 'mentions',
        name: 'Mentions',
        type: 'twitter-mentions',
        details: [{ type: 'line-chart' }, { type: 'twitter-mentions' }],
      },
    ],
  },
  {
    id: 'traffic',
    name: 'Traffic',
    services: ['google'],
    description: 'Analyse website traffic.',
    fields: [
      { fieldId: 'analytics-page-views', name: 'Page Views', type: 'number' },
      { fieldId: 'analytics-visitors', name: 'Visitors', type: 'number' },
      {
        fieldId: 'analytics-countries',
        name: 'Countries',
        type: 'countries',
        valueType: 'percentage',
        details: [{ type: 'bar-chart' }],
      },
      {
        fieldId: 'analytics-pages',
        name: 'Top Pages',
        type: 'list',
        valueType: 'number',
        details: [{ type: 'bar-chart' }],
      },
      {
        fieldId: 'analytics-referrers',
        name: 'Referrers',
        type: 'list',
        valueType: 'number',
        details: [{ type: 'bar-chart' }],
      },
    ],
  },
];

export const VIEW_INTERVALS = [
  { id: 'day', name: '1 Day' },
  { id: 'week', name: '1 Week' },
  { id: 'month', name: '1 Month' },
  { id: 'year', name: '1 Year' },
];

export const getViewInfo = (id: string): any => {
  return _.find(VIEWS, { id });
};

export const getViewInfoByIndex = (index: number): any => {
  return VIEWS[index];
};

export const getViewInfoIndex = (id: string): any => {
  return _.findIndex(VIEWS, { id });
};

export const getConnectedRequiredServices = ({
  viewInfo,
  serviceConnections,
}: {
  viewInfo: any;
  serviceConnections: any[];
}) => {
  const connectedServices = _.filter(
    serviceConnections,
    (serviceConnection) =>
      viewInfo.services.includes(serviceConnection.data.serviceId) &&
      !!serviceConnection.data.isConnected,
  );

  return connectedServices;
};

export const hasARequiredConnectedService = ({
  viewInfo,
  serviceConnections,
}: {
  viewInfo: any;
  serviceConnections: any[];
}) => {
  const connectedServices = getConnectedRequiredServices({
    viewInfo,
    serviceConnections,
  });

  return !!connectedServices.length;
};

export const formatValue = ({
  type,
  value,
  data = {},
}: {
  type: string;
  value: any;
  data: any;
}) => {
  let formattedValue = value;

  switch (true) {
    case type === 'number':
      formattedValue = numberWithCommas(value || 0);
      break;

    case type === 'currency':
      formattedValue = `${getCurrencySymbol(data.currency)}${numberWithCommas(
        value || 0,
      )}`;
      break;

    case type === 'percentage':
      formattedValue = `${numberWithCommas(value || 0)}%`;
      break;

    default:
      break;
  }

  return formattedValue;
};

const DEFAULT_VIEW_CARD_DETAIL: ViewCardDetail = {
  type: 'line-chart',
};

export const getViewCardDetails = (fieldInfo: any): ViewCardDetail[] => {
  return fieldInfo.details || [DEFAULT_VIEW_CARD_DETAIL];
};

export const detailsHaveChart = (details: any[]) => {
  return true;
};

const DEMOS: any = {
  growth,
  newsletter,
  social,
  traffic,
};

export const loadViewGroups = async ({
  viewId,
  interval,
}: {
  viewId: string;
  interval: string;
}) => {
  return DEMOS[viewId];
};
