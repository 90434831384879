import React, { useState, useEffect, useCallback, FC } from 'react';
import {
  Backdrop,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import { loadViewGroups } from '../../../lib/views/utils';
import { PageLoading } from '../ui';
import ViewCard from '../view-card/ViewCard';
import ViewCardDetails from '../view-card-details/ViewCardDetails';
import './ViewGrid.scss';

interface Props {
  viewId: string;
  viewInfo: any;
}

const ViewGrid: FC<Props> = ({ viewId, viewInfo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [viewGroups, setViewGroups] = useState<any[]>([]);
  const [selectedFieldInfo, setSelectedFieldInfo]: any = useState(null);
  const [selectedViewGroup, setSelectedViewGroup] = useState<any>(null);
  const [viewInterval, setViewInterval] = useState('day');

  const loadView = useCallback(
    async (isReload?: boolean) => {
      if (!isReload) {
        setIsLoading(true);
      }

      try {
        const groups: any = await loadViewGroups({
          viewId,
          interval: viewInterval,
        });

        setViewGroups(groups);
      } catch (error) {}

      setIsLoading(false);
    },
    [viewId, viewInterval],
  );

  const showField = (field: any, viewGroup: any) => {
    setSelectedFieldInfo(field);
    setSelectedViewGroup(viewGroup);
  };

  const hideField = () => {
    setSelectedFieldInfo(null);
    setSelectedViewGroup(null);
    setViewInterval('day');
  };

  useEffect(() => {
    loadView();

    const interval = setInterval(() => {
      loadView(true);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [loadView]);

  return (
    <div className="view-grid">
      <div className="view-groups">
        {viewGroups.map((viewGroup: any) => (
          <div key={viewGroup.id} className="view-group">
            {viewGroup.name && (
              <Typography className="view-group-name" variant="h6">
                {viewGroup.name}
              </Typography>
            )}

            <Grid className="views" spacing={3} container>
              {viewInfo.fields.map((fieldInfo: any, index: number) => {
                return (
                  <Grid key={index} xs={12} sm={4} item>
                    <motion.div
                      className="view-card-outer"
                      onClick={() => showField(fieldInfo, viewGroup)}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.99 }}
                    >
                      <ViewCard fields={viewGroup.fields[fieldInfo.fieldId]} />
                    </motion.div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        ))}
      </div>

      <Dialog
        className="view-grid-modal"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!!selectedFieldInfo}
        onClose={hideField}
        BackdropComponent={Backdrop}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {selectedFieldInfo && (
          <ViewCardDetails
            viewFields={selectedViewGroup.fields}
            fieldInfo={selectedFieldInfo}
            viewInterval={viewInterval}
            setViewInterval={setViewInterval}
            hideField={hideField}
          />
        )}
      </Dialog>

      {!isLoading && viewGroups.length === 0 && (
        <Card className="preparing">
          <CardContent>
            <Typography>
              You'll be notified
              <br />
              when your view is ready.
            </Typography>
          </CardContent>

          <CardContent>
            <CircularProgress />
          </CardContent>
        </Card>
      )}

      {isLoading && <PageLoading />}
    </div>
  );
};

export default ViewGrid;
